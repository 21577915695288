import Keycloak from "keycloak-js";
import { Product, PackageSize, SeedBatch, CultivationAction, Location, WastageReason, Pest, Campaign, EventType, Event, StorageDiscard, Facility, TreeSpecies, PeatBatch, GritBatch, ProductInfo } from "../generated/client";

export interface EventListFilters {
  product?: Product,
  startDate?: Date,
  endDate?: Date,
  firstResult?: number
  type?: EventType
  location?: Location
  batchNumber?: string
  woodType?: string
}

export interface StoreState {
  keycloak?: Keycloak
  authenticated: boolean
  storageDiscards: StorageDiscard[]
  packageSizes: PackageSize[]
  packageSize: PackageSize
  seedBatches: SeedBatch[]
  seedBatch: SeedBatch
  performedCultivationAction: CultivationAction
  performedCultivationActions: CultivationAction[]
  locale: string
  location: Location
  locations: Location[]
  wastageReason: WastageReason
  wastageReasons: WastageReason[]
  pests: Pest[],
  error?: ErrorMessage,
  campaigns: Campaign[],
  eventListFilters: EventListFilters,
  events: Event[],
  facility: Facility,
  treeSpecies: TreeSpecies[],
  tree: TreeSpecies,
  peatBatches: PeatBatch[],
  gritBatches: GritBatch[],
  productInfos: ProductInfo[],
  productInfo: ProductInfo
}

/**
 * An interface describing an error message
 */
export interface ErrorMessage {
  title?: string,
  message?: string,
  exception?: Error
}

/**
 * An interface describing packagesize options
 */
export interface PackageSizeOptions {
  key?: string;
  value?: string;
  text?: string;
}

/**
 * An interface describing visualize packings data
 */
export interface VisualizePackingsData {
  time: number;
  count: number | undefined;
  label?: number;
};

/**
 * An interface describing batch type
 */
export enum BatchType {
  PEAT = "PEAT",
  GRIT = "GRIT"
}