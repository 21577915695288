import * as React from "react";
import Keycloak from 'keycloak-js';
import * as actions from "../../actions";
import { ErrorMessage, StoreState } from "../../types";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import Api from "../../api";
import { NavLink } from 'react-router-dom';
import { Facility, Location } from "../../generated/client";
import strings from "../../localization/strings";

import {
  List,
  Button,
  Grid,
  Loader
} from "semantic-ui-react";

export interface Props {
  keycloak?: Keycloak;
  locations?: Location[];
  facility: Facility;
  onlocationFound?: (location: Location[]) => void,
  onError: (error: ErrorMessage | undefined) => void
}

export interface State {
  locations: Location[];
}

class LocationList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      locations: []
    };
  }

  /**
   * Component did mount life-sycle event
   */
  public async componentDidMount() {
    const { keycloak, facility, onError, onlocationFound } = this.props;
    try {
      if (!keycloak) {
        return;
      }

      const locationService = await Api.getLocationsService(keycloak);
      const location = await locationService.listLocations({ facility: facility });

      onlocationFound && onlocationFound(location);
    } catch (e: any) {
      Api.handleApiError(e, onError);
    }
  }

  /**
   * Render production line list view
   */
  public render() {

    const { locations } = this.props;

    if (!locations) {
      return (
        <Grid style={{paddingTop: "100px"}} centered>
          <Loader inline active size="medium" />
        </Grid>
      );
    }

    const sortedLocations = locations.slice().sort(
      (a: Location, b: Location) =>
        a.name.localeCompare(b.name, undefined, { sensitivity: "base" })
    );

    const LocationElements = sortedLocations.sort(this.comparelocation).map((location, i) => {
      const LocationPath = `/locations/${location.id}`;
      return (
        <List.Item style={i % 2 === 0 ? {backgroundColor: "#ddd"} : {}} key={location.id}>
          <List.Content floated='right'>
            <NavLink to={LocationPath}>
              <Button className="submit-button">{strings.open}</Button>
            </NavLink>
          </List.Content>
          <List.Content>
            <List.Header style={{paddingTop: "10px"}}>{location.name}</List.Header>
          </List.Content>
        </List.Item>
      );
    });

    return (
      <Grid>
        <Grid.Row className="content-page-header-row" style={{flex: 1,justifyContent: "space-between", paddingLeft: 10, paddingRight: 10}}>
          <h2>{strings.locations}</h2>
          <NavLink to="/createLocation">
            <Button className="submit-button">{strings.newLocation}</Button>
          </NavLink>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <List divided animated verticalAlign='middle'>
              {LocationElements}
            </List>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  /**
   * Compares locations by line number ignoring letters
   *
   * @param location1 Production line to compare
   * @param Location2 Production line  to compare
   */
  private comparelocation(Location1: Location, Location2: Location) {
    const lineNumber1 = Location1.name;
    const lineNumber2 = Location2.name;

    const lineValue1 = lineNumber1 ? Number(lineNumber1.replace(/\D/g, "")) : Number.MAX_SAFE_INTEGER;
    const lineValue2 = lineNumber2 ? Number(lineNumber2.replace(/\D/g, "")) : Number.MAX_SAFE_INTEGER;
    return lineValue1 - lineValue2;
  }
}

/**
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 */
export function mapStateToProps(state: StoreState) {
  return {
    locations: state.locations,
    location: state.location,
    facility: state.facility
  };
}

/**
 * Redux mapper for mapping component dispatches
 *
 * @param dispatch dispatch method
 */
export function mapDispatchToProps(dispatch: Dispatch<actions.AppAction>) {
  return {
    onlocationFound: (location: Location[]) => dispatch(actions.locationsFound(location)),
     onError: (error: ErrorMessage | undefined) => dispatch(actions.onErrorOccurred(error))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationList);