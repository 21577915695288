/* tslint:disable */
/* eslint-disable */
/**
 * UPM-metsa-api
 * UPM Metsä API specification
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum BatchUnit {
    Cells = 'CELLS',
    Boxes = 'BOXES'
}

export function BatchUnitFromJSON(json: any): BatchUnit {
    return BatchUnitFromJSONTyped(json, false);
}

export function BatchUnitFromJSONTyped(json: any, ignoreDiscriminator: boolean): BatchUnit {
    return json as BatchUnit;
}

export function BatchUnitToJSON(value?: BatchUnit | null): any {
    return value as any;
}

