import * as React from "react";
import Keycloak from 'keycloak-js';
import * as actions from "../../actions";
import { ErrorMessage, StoreState } from "../../types";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import Api from "../../api";
import { Facility, LocalizedValue, TreeSpecies } from "../../generated/client";
import { Navigate } from 'react-router-dom';
import strings from "../../localization/strings";

import {
  Grid,
  Button,
  Form,
  Input,
} from "semantic-ui-react";
import LocalizedValueInput from "../LocalizedValueInput";
import { FormContainer } from "../FormContainer";

export interface Props {
  keycloak?: Keycloak;
  tree?: TreeSpecies;
  facility: Facility;
  onTreeSpeciesCreated?: (tree: TreeSpecies) => void;
  onError: (error: ErrorMessage | undefined) => void;
}

export interface State {
  treeData: TreeSpecies
  redirect: boolean;
}

class CreateTreeSpecies extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      treeData: { name: [] },
      redirect: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  /**
   * Handle form submit
   */
  public async handleSubmit() {
    const { keycloak, facility, onError } = this.props;
    const { treeData } = this.state;
    try {
      if (!keycloak) {
        return
      }

      const treeSpeciesObject: TreeSpecies = {
        name: treeData.name
      };

      const treeSpeciesService = await Api.getTreeSpeciesService(keycloak);
      await treeSpeciesService.createTreeSpecies({
        treeSpecies: treeSpeciesObject,
        facility: facility
      });
      this.setState({redirect: true});
    } catch (e: any) {
      Api.handleApiError(e, onError);
    }
  }

  /**
   *  Updates performed tree name
   *
   * @param name localized entry representing name
   */
  private updateName = (name: LocalizedValue[]) => {
    this.setState({
      treeData: {...this.state.treeData, name: name}
    });
  }

  /**
   * Render create tree view
   */
  public render() {
    if (this.state.redirect) {
      return <Navigate replace={true} to="/treeSpecies"/>;
    }
    return (
      <Grid>
        <Grid.Row className="content-page-header-row">
          <Grid.Column width={8}>
            <h2>{strings.newTreeSpecies}</h2>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <FormContainer>
              <Form.Field required>
                <label>{strings.treeSpeciesName}</label>
                <LocalizedValueInput
                  onValueChange={this.updateName}
                  value={this.state.treeData.name}
                  languages={["fi", "en"]}
                />
              </Form.Field>
              <Form.Field>
              <label>{strings.treeSpeciesCode}</label>
                <Input
                  value={this.state.treeData.code}
                  onChange={(e) => this.setState({treeData: {...this.state.treeData!, code: e.currentTarget.value}})}
                />
              </Form.Field>
              <Button className="submit-button" onClick={this.handleSubmit} type='submit'>{strings.save}</Button>
            </FormContainer>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

/**
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 */
export function mapStateToProps(state: StoreState) {
  return {
    treeSpecies: state.treeSpecies,
    tree: state.tree,
    facility: state.facility
  };
}

/**
 * Redux mapper for mapping component dispatches
 *
 * @param dispatch dispatch method
 */
export function mapDispatchToProps(dispatch: Dispatch<actions.AppAction>) {
  return {
    onTreeSpeciesCreated: (treeSpeciesAction: TreeSpecies) => dispatch(actions.treeSpeciesCreated(treeSpeciesAction)),
    onError: (error: ErrorMessage | undefined) => dispatch(actions.onErrorOccurred(error))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateTreeSpecies);