import * as React from "react";
import Keycloak from 'keycloak-js';
import Api from "../../api";
import { NavLink } from 'react-router-dom';
import { Event, EventType, Facility, Product, Location, Batch } from "../../generated/client";
import strings from "../../localization/strings";
import moment from "moment";
import * as actions from "../../actions";
import { StoreState, ErrorMessage, EventListFilters } from "../../types/index";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import {
  Button,
  Grid,
  Loader,
  Form,
  DropdownProps,
  TextAreaProps,
  Table,
  Visibility
} from "semantic-ui-react";
import { DateInput } from 'semantic-ui-calendar-react';
import LocalizedUtils from "../../localization/localizedutils";

const CELLS_TO_PLATFORM_MULTIPLIER = 18;

/**
 * Interface representing component properties
 */
interface Props {
  keycloak?: Keycloak;
  events: Event[];
  eventListFilters?: EventListFilters;
  location?: any,
  facility: Facility;
  onEventListFiltersUpdated?: (filters: EventListFilters) => void,
  onEventsFound?: (events: Event[]) => void,
  onProductsFound?: (products: Product[]) => void,
  onError?: (error: ErrorMessage | undefined) => void
}

/**
 * Interface representing component state
 */
interface State {
  status: string
  loading: boolean
  errorCount: number,
  loadingFirstTime: boolean,
  locations: Location[],
  previousBottomVisibility?: number,
  batches: Batch[]
}

/**
 * React component for displaying list of batches
 */
class EventList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      locations: [],
      status: "OPEN",
      loading: false,
      errorCount: 0,
      loadingFirstTime: false,
      batches: []
    };;
  }

  /**
   * Component did mount life-sycle event
   */
  public async componentDidMount() {
    const { keycloak, facility, eventListFilters } = this.props;
    try {
      if (keycloak) {
        const locationsService = await Api.getLocationsService(keycloak);
        const batchesService = await Api.getBatchesService(keycloak);
        const batches = await batchesService.listBatches({ facility: facility });
        const locations = await locationsService.listLocations({ facility: facility });
        this.setState({
          locations: locations,
          batches: batches
        });
      }

      await this.updateEvents(eventListFilters || {});
    } catch(e) {
      console.error(e);
    }
  }

  /**
   * Gets location string based on event type
   *
   * @param event Event
   * @returns string
   */
  private getEventLocationString = (event: Event) => {
    const eventData = event.data as any;
    const eventType = event.type;

  const locations = this.state.locations;

  const getLocationName = (id: string | undefined): string =>
    id ? locations.find(location => location.id === id)?.name || "-" : "-";

  const location: string = (() => {
    switch (eventType) {
      case EventType.Transfer:
      case EventType.Packing: {
        const sourceLocation = getLocationName(eventData.sourceLocationId);
        const targetLocation = getLocationName(eventData.targetLocationId);
        return `${sourceLocation} - ${targetLocation}`;
      }
      case EventType.Load:
        return getLocationName(eventData.sourceLocationId);
      case EventType.Sowing:
      case EventType.Wastage:
        return getLocationName(eventData.locationId);
      case EventType.PerformedAction:
        return "-";
      default:
        return "-";
    }
  })();

    return location;
  }

  private renderEventTableRow = (event: Event) => {
    const startTime = moment(event.startTime).format("DD.MM.YYYY");
    const eventData = event.data as any;
    const location = eventData.locationId ? this.state.locations.find(location => location.id === eventData.locationId) : undefined;
    const locationString = this.getEventLocationString(event);
    const batchNumber = this.state.batches.find(batch => batch.id === event.batchId)?.batchNumber ?? "";
    const cellCount = event.type === EventType.Packing ? "-" : (eventData.amount || 0);
    const platforms = event.type === EventType.Packing ? "-" : (cellCount / CELLS_TO_PLATFORM_MULTIPLIER).toFixed(2);
    const amount = event.type === EventType.Packing ? "-" : (eventData.amount || 0);

    const largeTrayAmount = event.type === EventType.Packing ? eventData.largeTrayAmount : "-";
    const boxAmount = event.type === EventType.Packing ? eventData.boxAmount : "-";

    if (this.props.eventListFilters && this.props.eventListFilters.location) {
      if (!location) {
        return null;
      }
      if (location.id !== this.props.eventListFilters.location.id) {
        return null;
      }
    }

    return (
      <Table.Row key={event.id}>
        <Table.Cell>{ batchNumber }</Table.Cell>
        <Table.Cell>{ LocalizedUtils.getLocalizedEventType(event.type) }</Table.Cell>
        <Table.Cell>{ startTime }</Table.Cell>
        <Table.Cell>{ locationString }</Table.Cell>
        <Table.Cell>{ amount }</Table.Cell>
        <Table.Cell>{ platforms }</Table.Cell>
        <Table.Cell>{ largeTrayAmount }</Table.Cell>
        <Table.Cell>{ boxAmount }</Table.Cell>
        <Table.Cell textAlign="right">
          <NavLink to={`/events/${event.id}`}>
              <Button className="submit-button">{strings.open}</Button>
          </NavLink>
        </Table.Cell>
      </Table.Row>
    )
  }

  /**
   * Render batch list view
   */
  public render() {
    if (this.state.loading && this.state.loadingFirstTime) {
      return (
        <Grid style={{paddingTop: "100px"}} centered>
          <Loader inline active size="medium" />
        </Grid>
      );
    }

    const possibleLoader = (): any => {
      if (this.state.loading) {
        return <Loader
          style={{ marginLeft: "auto", marginRight: "auto" }}
          inline
          active
          size="medium" />
      }
    }

    const events = this.props.events || [];

    const tableRows = events.map((event, i) => this.renderEventTableRow(event));

    const { eventListFilters } = this.props;
    const startDateText = eventListFilters && eventListFilters.startDate ? moment(eventListFilters.startDate).format("DD.MM.YYYY") : "";
    const endDateText = eventListFilters && eventListFilters.endDate ? moment(eventListFilters.endDate).format("DD.MM.YYYY") : "";
    const typeFilterText = eventListFilters && eventListFilters.type ? LocalizedUtils.getLocalizedEventType(eventListFilters.type) : strings.allEventTypes;
    const locationFilterText = eventListFilters && eventListFilters.location ? eventListFilters.location.name : strings.allLocations;
    return (
      <Grid>
        <Grid.Row className="content-page-header-row" style={{flex: 1,justifyContent: "space-between", paddingLeft: 10, paddingRight: 10}}>
          <h2>{strings.events}</h2>
          <NavLink to="/createEvent">
            <Button className="submit-button">{strings.newEvent}</Button>
          </NavLink>
        </Grid.Row>
        <Grid.Row>
          <Form>
            <Form.Field>
              <div style={{display:"inline-block", paddingRight: "2rem"}}>
                <label>{strings.dateRange}</label>
                <DateInput localStartization="fi-FI" dateFormat="DD.MM.YYYY" onChange={this.onChangeStartDate} name="date" value={startDateText} />
              </div>
              <div style={{display:"inline-block", paddingRight: "2rem"}}>
                <DateInput localStartization="fi-FI" dateFormat="DD.MM.YYYY" onChange={this.onChangeEndDate} name="date" value={endDateText} />
              </div>
              </Form.Field>
              <Form.Field>
              <div style={{display:"inline-block", paddingTop: "2rem"}}>
                <label>{strings.labelBatchNumber}</label>
                <Form.Input name="product" onChange={(e) => this.onChangeBatchNumber(e.target.value)} />
              </div>
              <div style={{display:"inline-block", paddingTop: "2rem"}}>
                <label>{strings.cultivationAction}</label>
                <Form.Select name="event-type" options={this.renderEventTypeOptions()} text={typeFilterText} onChange={this.onChangeEventTypeFilter} />
              </div>
              <div style={{display:"inline-block", paddingTop: "2rem"}}>
                <label>{strings.location}</label>
                <Form.Select name="location" options={this.renderLocationOptions()} text={locationFilterText} onChange={this.onChangeLocationFilter} />
              </div>
            </Form.Field>
          </Form>
        </Grid.Row>
        <Grid.Row>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
          <Visibility onUpdate={this.loadMoreEvents}>
            <Table selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>{ strings.labelBatchNumber }</Table.HeaderCell>
                  <Table.HeaderCell>{ strings.cultivationAction }</Table.HeaderCell>
                  <Table.HeaderCell>{ strings.labelStartTime }</Table.HeaderCell>
                  <Table.HeaderCell>{ strings.labelLocation }</Table.HeaderCell>
                  <Table.HeaderCell>{ strings.amount }</Table.HeaderCell>
                  <Table.HeaderCell>{ strings.platforms }</Table.HeaderCell>
                  <Table.HeaderCell>{ strings.labelLargeTrayAmount}</Table.HeaderCell>
                  <Table.HeaderCell>{ strings.labelBoxAmount}</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                { tableRows }
              </Table.Body>
            </Table>
            </Visibility>
          </Grid.Column>
        </Grid.Row>
        {possibleLoader()}
      </Grid>
    );
  }

  /**
   * Handles changing batch number
   */
  private onChangeBatchNumber = async (value: string) => {
    await this.updateEvents({...this.props.eventListFilters, batchNumber: value as string});
  }

  /**
   * Handles changing start date
   */
  private onChangeStartDate = async (e: any, { value }: DropdownProps) => {
    const date =  moment(value as any, "DD.MM.YYYY");
    await this.updateEvents({...this.props.eventListFilters, startDate: date.toDate()});
  }

  /**
   * Handles changing end date
   */
  private onChangeEndDate = async (e: any, { value }: DropdownProps) => {
    const date =  moment(value as any, "DD.MM.YYYY");
    await this.updateEvents({...this.props.eventListFilters, endDate: date.toDate()});
  }

  /**
   * Handles changing timber filter
   */
  private onChangeTimber = async (value: string) => {
    await this.updateEvents({...this.props.eventListFilters, woodType: value});
  }

  /**
   * Handles changing selected product
   */
  private onChangeEventTypeFilter = async (e: any, { name, value }: DropdownProps | TextAreaProps) => {
    const type = (value || undefined) as any;
    await this.updateEvents({...this.props.eventListFilters, type});
  }

  /**
   * Handles the change of location filter
   */
  private onChangeLocationFilter = async (e: any, { name, value }: DropdownProps | TextAreaProps) => {
    const location = this.state.locations.find(location => location.id === value) || undefined;
    await this.updateEvents({...this.props.eventListFilters, location: location});
  }

  private renderLocationOptions = () => {
    if (!this.state.locations) {
      return [{text: strings.allLocations, value: ""}]
    }
    return [{text: strings.allLocations, value: "", key: ""}]
      .concat(
      this.state.locations.map(location => {
        return { text: location.name ?? "", value: location.id ?? "", key: location.id ?? "" }
      }));
  }

  /**
   * Renders dropdown options
   */
    private renderEventTypeOptions = () => {
    return [{text: strings.allEventTypes, value: ""}]
      .concat(
      Object.values(EventType).map(type => {
        return { text: LocalizedUtils.getLocalizedEventType(type), value: type }
      }));
    }

  private filtersChanged = (filters: EventListFilters, prevFilters?: EventListFilters): boolean => {
    if (!prevFilters) {
      return true;
    }

    const dateChanged = prevFilters.startDate !== filters.startDate || prevFilters.endDate !== filters.endDate;
    const productChanged = prevFilters.product !== filters.product;
    const typeChanged = prevFilters.type !== filters.type;
    return dateChanged || productChanged || typeChanged
  }

  /**
   * Loads more batches
   */
  private loadMoreEvents = async (e: any, { calculations }: any) => {
    const { eventListFilters } = this.props;
    if (calculations.bottomVisible === true && !this.state.loading && calculations.percentagePassed !== this.state.previousBottomVisibility) {
      const firstResult = ((eventListFilters || {}).firstResult || 0) + 20;
      await this.updateEvents({...eventListFilters, firstResult});
      // This prevents the updateEvents from being called repeatedly
      this.setState({previousBottomVisibility: calculations.percentagePassed})
    }
  }

  /**
   * Updates batch list
   */
  private updateEvents = async (filters: EventListFilters) => {
    const { keycloak, facility, eventListFilters, onError, onEventListFiltersUpdated, onEventsFound } = this.props;
    if (!keycloak) {
      return;
    }
    const prevFilters = eventListFilters;
    const startDateFilter = filters.startDate ? moment(filters.startDate) : moment();
    const endDateFilter = filters.endDate ? moment(filters.endDate) : moment();
    const createdBefore = endDateFilter.endOf("day").toISOString();
    const createdAfter = startDateFilter.startOf("day").toISOString();
    const firstResult = this.filtersChanged(filters, prevFilters) ? 0 : filters.firstResult || 0;
    this.setState({loading: true});
    try {
      const [eventsService] = await Promise.all([
        Api.getEventsService(keycloak)]);

      const [events] = await Promise.all([
        eventsService.listEvents({
          eventType: filters.type,
          batchId: this.state.batches.find(batch => batch.batchNumber === filters.batchNumber)?.id,
          createdAfter: createdAfter,
          createdBefore: createdBefore,
          firstResult: firstResult,
          maxResults: 20,
          facility: facility,
        }),
      ]);

      const updatedEvents = firstResult > 0 ? [...(this.props.events || []), ...events] : events;
      onEventsFound && onEventsFound(updatedEvents);
      onEventListFiltersUpdated && onEventListFiltersUpdated({
        startDate: startDateFilter.toDate(),
        endDate: endDateFilter.toDate(),
        firstResult: firstResult,
        product: filters.product || undefined,
        type: filters.type,
        location: filters.location
      });

      this.setState({loading: false, loadingFirstTime: false})
    } catch(e: any) {
      onError && Api.handleApiError(e, onError);
    }
  }
}

/**
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 */
export function mapStateToProps(state: StoreState) {
  return {
    eventListFilters: state.eventListFilters,
    events: state.events,
    locations: state.locations,
    facility: state.facility
  };
}

/**
 * Redux mapper for mapping component dispatches
 *
 * @param dispatch dispatch method
 */
export function mapDispatchToProps(dispatch: Dispatch<actions.AppAction>) {
  return {
    onEventListFiltersUpdated: (filters: EventListFilters) => dispatch(actions.eventListFiltersUpdated(filters)),
    onEventsFound: (events: Event[]) => dispatch(actions.eventsFound(events)),
    onError: (error: ErrorMessage | undefined) => dispatch(actions.onErrorOccurred(error))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventList);