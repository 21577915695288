import * as React from "react";
import Keycloak from 'keycloak-js';
import * as actions from "../../actions";
import { ErrorMessage, StoreState } from "../../types";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import Api from "../../api";
import { NavLink } from 'react-router-dom';
import { Facility, TreeSpecies } from "../../generated/client";
import strings from "../../localization/strings";

import {
  List,
  Button,
  Grid,
  Loader
} from "semantic-ui-react";
import LocalizedUtils from "../../localization/localizedutils";

export interface Props {
  keycloak?: Keycloak;
  treeSpecies?: TreeSpecies[];
  facility: Facility;
  onTreeSpeciesFound?: (treeSpecies: TreeSpecies[]) => void,
  onError: (error: ErrorMessage | undefined) => void;
}

export interface State {
  treeSpecies: TreeSpecies[];
}

class TreeSpeciesList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      treeSpecies: []
    };
  }

  /**
   * Component did mount life-sycle event
   */
  async componentDidMount() {
    const { keycloak, facility, onError, onTreeSpeciesFound } = this.props;
    try {
      if (!keycloak) {
        return;
      }

      const treeSpeciesService = await Api.getTreeSpeciesService(keycloak);
      const treeSpecies = await treeSpeciesService.listTreeSpecies({facility: facility});

      const sortedTreeSpecies: TreeSpecies[] = treeSpecies.slice().sort(
        (a: TreeSpecies, b: TreeSpecies) =>
          LocalizedUtils.getLocalizedValue(a.name).localeCompare(
            LocalizedUtils.getLocalizedValue(b.name),
            undefined,
            { sensitivity: "base" }
          )
      );

      onTreeSpeciesFound && onTreeSpeciesFound(sortedTreeSpecies);
      this.setState({treeSpecies: sortedTreeSpecies});
    } catch (e: any) {
      Api.handleApiError(e, onError);
    }
  }



  /**
   * Render treeSpecies list view
   */
  render() {
    if (!this.props.treeSpecies) {
      return (
        <Grid style={{paddingTop: "100px"}} centered>
          <Loader inline active size="medium" />
        </Grid>
      );
    }

    const renderTreeSpeciesItems = this.state.treeSpecies.map((tree, i) => {
      const treeSpeciesPaths = `/treeSpecies/${tree.id}`;
      return (
        <List.Item style={i % 2 === 0 ? {backgroundColor: "#ddd"} : {}} key={tree.id}>
          <List.Content floated='right'>
            <NavLink to={treeSpeciesPaths}>
              <Button className="submit-button">{strings.open}</Button>
            </NavLink>
          </List.Content>
          <List.Content>
            <List.Header style={{paddingTop: "10px"}}>{`${LocalizedUtils.getLocalizedValue(tree.name)} (${tree.code ?? ""})`}</List.Header>
          </List.Content>
        </List.Item>
      );
    });

    return (
      <Grid>
        <Grid.Row className="content-page-header-row" style={{flex: 1,justifyContent: "space-between", paddingLeft: 10, paddingRight: 10}}>
          <h2>{strings.treeSpecies}</h2>
          <NavLink to="/createTreeSpecies">
            <Button className="submit-button">{strings.newTreeSpecies}</Button>
          </NavLink>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <List divided animated verticalAlign='middle'>
              {renderTreeSpeciesItems}
            </List>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

/**
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 */
export function mapStateToProps(state: StoreState) {
  return {
    treeSpecies: state.treeSpecies,
    tree: state.tree,
    facility: state.facility
  };
}

/**
 * Redux mapper for mapping component dispatches
 *
 * @param dispatch dispatch method
 */
export function mapDispatchToProps(dispatch: Dispatch<actions.AppAction>) {
  return {
    onTreeSpeciesFound: (treeSpecies: TreeSpecies[]) => dispatch(actions.treeSpeciesFound(treeSpecies)),
    onError: (error: ErrorMessage | undefined) => dispatch(actions.onErrorOccurred(error))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TreeSpeciesList);